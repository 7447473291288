import React from 'react';
import PageHeader from '../components/PageHeader';
import { colors } from '@mui/material';
// import '../css/deansw.css'; // Update the CSS file name

const Staffrecruitment = () => {
    return (
        <div class="container my-4">
            <center><h4 style={{ textDecoration: "underline" }}>Staff Recruitment</h4></center>

            <div class="row">
            <div class="card mb-2">
                    <div class="card-header bg-warning text-white"><h5 style={{ color: "white" }}>Advertisement [No. 05/2024 dated : 29.11.2024]</h5></div>
                    <div class="card-body">
                    <p style={{color:"red"}}>Online application is closed ....</p>
                        
                        {/* <p style={{color:"red"}}>Online application is closed ....</p> */}
                        <a href="https://nitw.ac.in/api/static/files/advt5_2024_2024-11-29-15-55-18.pdf"><i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i> <font style={{ color: "#b80947" }}>Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>[No. 05/2024 dated : 29.11.2024]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 07.01.2025</p>

                        <a href="https://nitw.ac.in/api/static/files/office_court_2024-12-27-18-7-12.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 27.12.2024: Update on recruitment of Office Attendant / Lab Attendant positions notified against Recruitment Advertisement No. 05/2024</font> </a><br/><br/>

                        <a href="https://nitw.ac.in/api/static/files/Examination_Syllabus_2025-1-21-11-55-4.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 21.01.2025: Pattern of Examination and Indicative Syllabus for the Group 'B' & 'C' posts notified on Direct Recruitment basis against Advt. No. 05/2024 dated 29.11.2024
                        </font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/web_notice_05_2024_2025-2-3-17-30-39.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 03.02.2025: Web Notice & List of provisionally shortlisted / not-shortlisted candidates for Group 'A' posts and Group 'B' (Deputation) posts against Advt. No. 05/2024 dated 29.11.2024

                        </font> </a><br/><br/>

                        {/* <a href="https://nitw.ac.in/api/static/files/Notice_2024-9-24-19-28-14.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> Notice dt.24.09.2024 - Instructions for the candidates who have submitted their applications against Advertisement No. 03/2024 dt.16.08.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Addendum03_2024-10-1-20-5-50.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red"}}> 01.10.2024: Addendum to the list of provisionally shortlisted candidates for the posts notified vide Advt. No. 03/2024 dt.16.08.2024</font> </a><br/> */}
                        <table className="mt-2 table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Principal Scientific/ Technical Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/pto_2025-2-3-17-34-57.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Principal Students Activity and Sports (SAS) Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/PSASO_2025-2-3-17-34-16.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Deputy Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/dr_deputation_2025-2-3-17-33-27.pdf"> <font style={{ color: "green" }}>Level 1</font></a> </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Executive Engineer (Civil) (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ee_deputation_2025-2-3-17-32-34.pdf"> <font style={{ color: "green" }}>Level 1</font></a>  </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Assistant Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ar_deputation_2025-2-3-17-33-4.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Assistant Engineer (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ae_deputation_2025-2-3-17-31-54.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Superintendent</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Junior Engineer</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Library & Information Assistant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Students Activity and Sports (SAS) Assistant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Senior Assistant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Junior Assistant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Office Attendant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Lab Attendant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <a href="https://careers.nitw.ac.in/register/?next=/" className='btn btn-warning mt-2'> Online Application Link </a> */}
                    </div>

                </div>
                <div class="card mb-2">
                    <div class="card-header bg-primary text-white"><h5 style={{ color: "white" }}>Advertisement [No. 04/2024 dated : 09.09.2024]</h5></div>
                    <div class="card-body">
                    <p style={{color:"red"}}>Online application is closed ....</p>
                        <a href="https://nitw.ac.in/api/static/files/dvt2024_09-09_2024-9-9-18-14-3.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i> <font style={{ color: "#b80947" }}>Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>[No. 04/2024 dated 09.09.2024]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 13.10.2024</p>
                        <a href="https://nitw.ac.in/api/static/files/webnotice_2024-10-27-20-35-42.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red"}}> 27.10.2024:  Instructions for the candidates who have submitted their applications for the post of
                        REGISTRAR against Advertisement No. 02/2024 dt.05.07.2024 and 04/2024 dt.09.09.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Addendum_Registrar_2024-11-3-11-23-32.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 03.11.2024:  Addendum to the list of provisionally shortlisted candidates for the post of Registrar (on Deputation ISTC) notified against Advt. No. 02/2024 dt.05.07.2024 & 04/2024 dt.09.09.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Advt02042024_2024-12-11-20-14-13.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 11.12.2024: The results of the selection process for the posts notified against Advt. No. 02/2024 & 04/2024. </font> </a><br/><br/>
                        <table className="table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Registrar</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/Registrar_2024_2024-10-27-20-36-52.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <a href="https://careers.nitw.ac.in/register/?next=/" className='btn btn-primary mt-2'> Online Application Link </a> */}
                    </div>

                </div>
                <div class="card mb-2">
                    <div class="card-header bg-warning text-white"><h5 style={{ color: "white" }}>Advertisement [No. 03/2024 dated : 16.08.2024]</h5></div>
                    <div class="card-body">
                        <p style={{color:"red"}}>Online application is closed ....</p>
                        <a href="https://nitw.ac.in/api/static/files/Advt_3_of_2024_2024-8-16-12-36-57.pdf"><i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i> <font style={{ color: "#b80947" }}>Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>[No. 03/2024 dated : 16.08.2024]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 09.09.2024</p>
                        <a href="https://nitw.ac.in/api/static/files/Notice_2024-9-24-19-28-14.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> Notice dt.24.09.2024 - Instructions for the candidates who have submitted their applications against Advertisement No. 03/2024 dt.16.08.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Addendum03_2024-10-1-20-5-50.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red"}}> 01.10.2024: Addendum to the list of provisionally shortlisted candidates for the posts notified vide Advt. No. 03/2024 dt.16.08.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Advt032024_2024-12-11-20-13-33.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite"}}> 11.12.2024: The results of the selection process for the posts notified against Advt. No. 03/2024.</font> </a><br/><br/>
                        <table className="mt-2 table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Principal Technical Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/PTO_2024-9-27-16-4-16.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Principal Students Activity and
                                    Sports (SAS) Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/psaso_2024-9-24-19-28-42.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Deputy Registrar</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/drdr_2024-9-24-19-29-13.pdf"> <font style={{ color: "green" }}>Level 1</font></a>
                                    </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Deputy Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td>
                                    <a href="https://nitw.ac.in/api/static/files/drdp_2024-9-24-19-29-43.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Assistant Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ardp_2024-9-24-19-30-8.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Technical Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/todr_2024-9-24-19-30-33.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Assistant Engineer (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/aedp_2024-9-24-19-31-1.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="card mb-2">
                    <div class="card-header bg-info text-white"><h5 style={{ color: "white" }}>Advertisement [No. 02/2024 dated : 05.07.2024]</h5></div>
                    <div class="card-body">
                        <p style={{color:"red"}}>Online application is closed ....</p>
                        <a href="https://nitw.ac.in/api/static/files/Advt_02_of_2024_2024-7-5-14-30-58.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "#b80947" }}> Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>Advertisement [No. 02/2024 dated : 05.07.2024]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 31.07.2024</p>
                        <a href="https://nitw.ac.in/api/static/files/notice_2024-9-13-18-32-18.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> Notice - Provisional list of shortlisted/ not-shortlisted candidates for the post of Executive Engineer (Electrical) on Direct Recruitment and Executive Engineer (Civil) on Deputation (ISTC)</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Addendum_recruitment_2024-9-24-19-20-24.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 24.09.2024:  Addendum to the list of provisionally shortlisted/ not-shortlisted candidates for the posts notified vide Advertisement No. 02/2024 dated 05.07.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/webnotice_2024-10-27-20-35-42.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 27.10.2024:  Instructions for the candidates who have submitted their applications for the post of
                        REGISTRAR against Advertisement No. 02/2024 dt.05.07.2024 and 04/2024 dt.09.09.2024</font> </a><br/><br/>
                        {/* <a href="https://nitw.ac.in/api/static/files/Addendum_Registrar_2024-11-3-11-23-32.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 03.11.2024:  Addendum to the list of provisionally shortlisted candidates for the post of Registrar (on Deputation ISTC) notified against Advt. No. 02/2024 dt.05.07.2024 & 04/2024 dt.09.09.2024</font> </a><br/><br/> */}
                        <a href="https://nitw.ac.in/api/static/files/Addendum_Registrar_2024-11-3-11-23-32.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 03.11.2024:  Addendum to the list of provisionally shortlisted candidates for the post of Registrar (on Deputation ISTC) notified against Advt. No. 02/2024 dt.05.07.2024 & 04/2024 dt.09.09.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Advt022024_2024-12-11-20-13-4.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue" ,animation: "blinker 2s step-start infinite"}}> 11.12.2024: The results of the selection process for the posts notified against Advt. No. 02/2024.</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Advt02042024_2024-12-11-20-14-13.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 11.12.2024: The results of the selection process for the posts notified against Advt. No. 02/2024 & 04/2024.</font> </a><br/><br/>
                        <table className="mt-2 table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Registrar</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/Registrar_2024_2024-10-27-20-36-52.pdf"> <font style={{ color: "green" }}> Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Executive Engineer
                                    (Electrical)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/EE_Electrical_2024-9-13-18-34-21.pdf"> <font style={{ color: "green" }}>Level 1</font></a><br/>
                                    <a href="https://nitw.ac.in/api/static/files/List_EE_CE_2024-9-24-19-21-25.pdf"> <font style={{ color: "green" }}>Level 1(updated)</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Executive Engineer(Civil)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/EE_Civil_2024-9-13-18-33-42.pdf"> <font style={{ color: "green" }}>Level 1</font></a>
                                    </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="card mb-2">
                    
                    <div class="card-header">

                <a href="https://nitw.ac.in/page/?url=/jobsrecruitment/Nonteaching"className='btn btn-primary mt-2'>Click on the button to view the old advertisements</a>
                </div>
                </div>
            

                

            </div>
        </div>
    )
}
export default Staffrecruitment;