// import React from 'react';
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
import { staffData } from '../constants/PicData';


const Officers = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const handleHover = (index) => {
        setHoveredIndex(index);
      };
    
      const handleHoverEnd = () => {
        setHoveredIndex(null);
      };
    return (<>
     <img className='img-fluid' alt='' src='https://nitw.ac.in/api/static/files/officers_img_2025-2-7-16-20-43.jpg'/>
        <div class="container mt-2">
            {/* <center><h4 style={{ textDecoration: "underline" }}>Officers</h4></center> */}
           
            <div className="row">              
                <table className="table table-bordered table-responsive table-hover">
                    <thead>
                        
                    </thead>
                    <tbody>
                    <tr>
                            <th>
                                S.No
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Designation 
                            </th>
                            <th>
                                Department
                            </th>
                        </tr>
                       {staffData.map((data, index) => (
                            <tr>
                            <td>
                            {index+1 }
                            </td>
                            <td>
                             {data.name}
                            </td>
                            <td>
                             {data.designation}
                            </td>
                            <td>
                            {data.department}
                            </td>
                        </tr>
                        ))}
                        


                    </tbody>
                </table>

            </div>
        </div>
        </>
    )
}
export default Officers;