// import { useState } from "react";


const VisionMissionPage = () => {

    // const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    // const [peopleTab, setPeopleTab] = useState("Faculty");

    // const handleTabClick = (tabId) => {
    //     setActiveTab(tabId);
    //     console.log(tabId)
    // }

    // const handlePeopleTabClick = (tabId) => {
    //     setPeopleTab(tabId);
    //     console.log(tabId)
    // }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    
                    <div className="row">
                        
                        <div className="col-md-12">
                           
                           
                              
                                    {/* <div className="row vision-mission-section" style={{border:'none'}}>
                                        <div className="col-md-6 vision-section">
                                        <img src="../images/nit_build2.png" alt="" className="img-fluid" />
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>

                                            <p>Towards a Global Knowledge Hub, striving continuously in pursuit of excellence in Education, Research, Entrepreneurship and Technological services to the society</p>
                                            
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">Imparting total quality education to develop innovative, entrepreneurial and ethical future professionals fit for globally competitive environment.</p>
                                            <p className="  mb-2">Allowing stakeholders to share our reservoir of experience in education and knowledge for mutual enrichment in the field of technical education.</p>
                                            <p className="  mb-2">Fostering product oriented research for establishing a self-sustaining and wealth creating centre to serve the societal needs.</p>
                                        </div>
                                    </div> */}
                                    <div className="row vision-mission-section" style={{border:'none'}}>
                                        <div className="col-md-6 vision-section">
                                       {/* <img src="../images/nit_build2.png" alt="" className="img-fluid" /> */}
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>

                                                <ul className="visbullets">
                                                 <li>Towards a Global Knowledge Hub, striving continuously in pursuit of excellence in Education, Research, Entrepreneurship and Technological services to the society</li>
                                                </ul>
                                            
                                            
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                                
                                                <ul className="visbullets">
                                                    <li>Imparting total quality education to develop innovative, entrepreneurial and ethical future professionals fit for globally competitive environment.</li>
                                                    <li>Allowing stakeholders to share our reservoir of experience in education and knowledge for mutual enrichment in the field of technical education.</li>
                                                    <li>Fostering product oriented research for establishing a self-sustaining and wealth creating centre to serve the societal needs.</li>
                                                </ul>
                                           
                                        </div>
                                    </div>
                               



                        </div>
                    </div>
                </section>
            </div>
        </div>
            
        </>
    );
}

export default VisionMissionPage;
