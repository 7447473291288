import React, { useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css';

const Visiting = () => {
    // Dynamically add Font Awesome CSS
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    return (
        <div className="container mt-2">
            <img 
                src='https://nitw.ac.in/api/static/files/NITW_transport_v2_2023-5-24-12-3-35.jpg' 
                alt="NIT Warangal Transport"
                className="img-fluid mb-4"
            />
            
            <center>
                <h4 style={{ textDecoration: "underline" }}>Mode of Travel</h4>
            </center>
            
            <div className="travel-info">
                <p>
                    <b style={{color:"#2b4675"}}><i className="fa-solid fa-plane me-2"></i>Air Route : </b> 
                    Hyderabad is the nearest Airport from Warangal. Take a train or a cab to our campus, 
                    which takes around two and half hours by road and two hours by rail.
                </p>
                
                <p>
                    <b style={{color:"#2b4675"}}><i className="fa-solid fa-train me-2"></i>By Rail : </b> 
                    Kazipet (3 kms) / Warangal (12 kms) are the nearest railheads. Many trains pass through 
                    Kazipet junction, which is generally preferred.
                </p>
                
                <p>
                    <b style={{color:"#2b4675"}}><i className="fa-solid fa-car me-2"></i>By Road : </b> 
                    NIT Warangal is 2½ hrs drive by cab on Hyderabad - Warangal National Highway number-202.
                </p>
            </div>

            <center>
                <h4 style={{ textDecoration: "underline" }} className="mt-4">Nearby places to visit</h4>
                <img 
                    src='https://nitw.ac.in/api/static/files/Near_by_places_to_visit_2024-5-29-13-1-2.jpg' 
                    alt="Nearby places to visit"
                    className="img-fluid mt-2"
                />
            </center>
        </div>
    );
}

export default Visiting;