// import React from 'react';
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
// import { MoEData } from '../constants/MoEEventsData';
import { staffData } from '../constants/PicData';


const MoEEvents = () => {
    const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const eventData = [
    // { title: "Partition Horrors Remembrance Day", 
    //   content:`<p>Details about Partition Horrors Remembrance Day.</p>
    //             <img src="https://nitw.ac.in/api/static/files/NITW_transport_v2_2023-5-24-12-3-35.jpg" 
    //                  alt="NIT Warangal Transport"
    //                  class="img-fluid mb-4"/>` 
    // },
    { title: "International Yoga Day-2024", 
      content: `<img src="https://nitw.ac.in/api/static/files/Yoga_Day_2024_2024-7-16-17-25-33.jpg" 
      alt="NIT Warangal Transport"
      class="img-fluid mb-4"/> `},
    // { title: "National Education Day Programme", content: "<p>Events and details about NEP.</p>" },
    { title: "Swachhata Pakhwada - 2024", 
      content: `<img src="https://nitw.ac.in/api/static/files/Swachhata_Pakhwada_initiative_Swachhata_Rally_2024-10-4-11-23-33.jpg" 
      alt="NIT Warangal Transport"
      class="img-fluid mb-4"/>
      <img src="https://nitw.ac.in/api/static/files/Certificate_of_Appreciation_2024-10-4-11-24-6.jpg" 
      alt="NIT Warangal Transport"
      class="img-fluid mb-4"/>
      <img src="https://nitw.ac.in/api/static/files/Swachhta_Pakhwada_campaign_Banner_2024-9-26-11-41-56.jpg" 
      alt="NIT Warangal Transport"
      class="img-fluid mb-4"/> `
    },
    // { title: "Constitution Day", content: "<p>Details about Constitution Day celebrations.</p>" },
    // { title: "Ek Bharat Shreshtha Bharat", content: "<p>Integration and unity events.</p>" },

    // { title: "Har Ghar Tiranga-2024", 
    //     content: `<img src="https://nitw.ac.in/api/static/files/independence-day-celebrations_2024-8-16-16-38-10.jpg" 
    //     alt="NIT Warangal Transport
    //     class="img-fluid mb-4"/>` 
    //   },

    { title: "Har Ghar Tiranga-2024", 
      content: `<img src="https://nitw.ac.in/api/static/files/independence-day-celebrations_2024-8-16-16-38-10.jpg" 
      alt="NIT Warangal Transport
      class="img-fluid mb-4"/>` 
    },
    { title: "Ek Ped Maa Ke Naam Campaign-2024", 
        content:` <img src="https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Naam-Banner_2024-8-14-12-58-36.jpg" 
                       alt="NIT Warangal Transport"
                       class="img-fluid mb-4"/>` 
      },
  ];

  return (
    <div class="container mt-2">
            <center><h4 style={{ textDecoration: "underline" }}>MoE Events</h4></center>
      {eventData.map((item, index) => (
        <div key={index} className="border-b">
         <button
                className="mb-2 p-2 w-100 text-start d-flex justify-content-between align-items-center"
                onClick={() => toggleAccordion(index)}
              >
                <span>→ {item.title}</span>
                <span className='text-end'>{openIndex === index ? "▲" : "▼"}</span>
                
              </button>
          {openIndex === index && (
            <div className="px-4 py-3 border-t" dangerouslySetInnerHTML={{ __html: item.content }} />
          )}
        </div>
      ))}
    </div>
  );
};
export default MoEEvents;